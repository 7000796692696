<template>
  <div class="main-view">
    <div class="settings-sidebar">
      <SettingsNavigation />
    </div>
    <div class="settings-main">
      <h1 class="headline">{{ $t("main.COUNTRY_SELECTION") }}</h1>

      <v-snackbar v-model="showAddedMessage" :timeout="2000" color="success">{{ $t("main.ADDED_MESSAGE") }}</v-snackbar>

      <div v-if="!organisation">
        <v-progress-circular indeterminate color="primary" />
      </div>

      <div v-if="organisation">
        <p class="intro-text" v-html="$t('main.COUNTRY_SELECTION_INTRO')" />

        <div v-if="allCountries == 'no'">
          <div>
            {{ $t("main.COUNTRY_SELECTION_ADD_COUNTRY") }}:

            {{ country }}

            <v-autocomplete
              v-model="country"
              :search-input.sync="countrySearch"
              @change="onCountry"
              :items="countries"
              item-text="nameShort"
              item-value="iso2"
              hint=""
              return-object
              solo

              :loading="countriesIsLoading"
              />
          </div>

          <div class="list-loading-bar">
            <v-progress-linear
              :active="organisationCountrySelectionsIsLoading"
              indeterminate
              height="4"
              class="ma-0"
            ></v-progress-linear>
          </div>

          <v-row>
            <v-col cols="6">

              {{ $t("main.YOUR_FAVORITES") }}
              <div v-if="organisationCountrySelectionsIsDone">
                <v-list :three-line="organisationCountrySelections.length == 0">
                  <template v-for="(item, index) in organisationCountrySelections">
                    <v-list-item :key="item.id">
                      <v-list-item-avatar><v-icon color="gray lighten-1">language</v-icon></v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.country.nameShort }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn color="primary" @click="deleteCountrySelection(item.id)" :loading="deleteInProgress[item.id]">-</v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider  v-if="index + 1 < organisationCountrySelections.length" :key="item.id+'__divider'"></v-divider>
                  </template>

                  <v-list-item v-if="organisationCountrySelections.length == 0">
                    <v-list-item-avatar><v-icon color="gray lighten-1">language</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("main.COUNTRY_SELECTION_SHOW_ALLCOUNTRIES") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ $t("main.COUNTRY_SELECTION") }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <div class="list-notice" v-if="organisationCountrySelections.length > 0">
                    {{ $t("main.COUNTRY_SELECTION_CLEAN_NOTICE") }}
                  </div>

                </v-list>
              </div>

            </v-col>
            <v-col cols="6">
              {{ $t("main.AVAILABLE_COUNTRIES") }}

              <v-list>
                <template v-for="(item, index) in nonFavoriteCountries" >
                  <v-list-item :key="item.id">
                    <v-list-item-avatar><v-icon color="gray lighten-1">language</v-icon></v-list-item-avatar>
                    <v-list-item-content>{{ item.nameShort }}</v-list-item-content>
                    <v-list-item-action>
                      <v-btn color="primary" @click="addFavorite(item.id)" :loading="addInProgress[item.id]">+</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider  v-if="index + 1 < countries.length" :key="item.id+'__divider'"></v-divider>
                </template>

              </v-list>

            </v-col>
          </v-row>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SettingsNavigation from "@/components/settings/SettingsNavigation";

export default {
  name: 'CountrySelectionView',
  data() {
    return {
      allCountries: "no",
      country: null,
      countrySearch: '',
      showAddedMessage: false,
      deleteInProgress: {},
      addInProgress: {}
    }
  },
  components: {
    SettingsNavigation
  },
  mounted: function() {
    this.countriesFetchAll();
    this.doOrganisationCountrySelectionsFetchAll()
  },
  computed: {
    ...mapState({
      organisation: state => state.authStore.organisation,
      countries: state => state.countriesStore.entries,
      countriesIsDone: state => state.countriesStore.isDone,
      countriesIsLoading: state => state.countriesStore.isLoading,
      organisationCountrySelections: state => state.organisationCountrySelectionsStore.entries,
      organisationCountrySelectionsIsDone: state => state.organisationCountrySelectionsStore.isDone,
      organisationCountrySelectionsIsLoading: state => state.organisationCountrySelectionsStore.isLoading,
    }),
    nonFavoriteCountries() {
      let nonFavoriteCountries = []
      for (let country of this.countries) {
        let isFavorite = this.organisationCountrySelections.some(el => el.country.id == country.id)
        if (!isFavorite) {
          nonFavoriteCountries.push(country)
        }
      }
      return nonFavoriteCountries
    }
  },
  methods: {
    ...mapActions({
      countriesFetchAll: "countriesStore/fetchAll",
      organisationCountrySelectionsFetchAll: "organisationCountrySelectionsStore/fetchAll",
      organisationCountrySelectionsCreate: "organisationCountrySelectionsStore/create",
      organisationCountrySelectionsDelete: "organisationCountrySelectionsStore/delete"
    }),
    doOrganisationCountrySelectionsFetchAll() {
      this.organisationCountrySelectionsFetchAll({
        force: true
      });
    },
    onCountry() {
      this.addFavorite(this.country.id)
    },
    addFavorite(countryId) {
      this.addInProgress[countryId] = true
      let alreadyInList = this.organisationCountrySelections.some(el => el.country.id == countryId)

      if (!alreadyInList) {
        this.organisationCountrySelectionsCreate({
          variables: {
            input: {
              organisation: this.organisation.id,
              country: countryId
            }
          }
        }).then(() => {
          this.doOrganisationCountrySelectionsFetchAll()
          this.showAddedMessage = true
          this.addInProgress[countryId] = false
        })
      }

      this.$nextTick(() => {
        this.country = null
        this.countrySearch = ''
      })
    },

    deleteCountrySelection(countrySelectionId) {
      this.$set(this.deleteInProgress, countrySelectionId, true)

      this.organisationCountrySelectionsDelete({
        id: countrySelectionId
      }).then(() => {
        this.doOrganisationCountrySelectionsFetchAll()
        this.$set(this.deleteInProgress, countrySelectionId, false)
      }).catch(() => {
        this.$set(this.deleteInProgress, countrySelectionId, false)
      })

    }
  },
  watch: {
  }
}
</script>

<style scoped>
  .main-view {
    position: relative;
    padding: 2rem;
    max-width: 1250px;
    margin: auto;
  }

  .settings-sidebar {
    top: 2rem;
    width: 225px;
    position: absolute;
    left: 0;
  }

  .settings-main {
    position: absolute;
    left: 250px;
    right: 0;
  }

  .intro-text {
    margin-bottom: 2rem;
  }

  .list-loading-bar {
    height: 6px;
  }

  .list-notice {
    margin-top: 2rem;
    color: gray;
  }

</style>