<template>
<div class="main-view">
    <div class="settings-sidebar">
      <SettingsNavigation />
    </div>
    <div class="settings-main">
      <h1 class="headline">{{ $t("main.MYJOURNEY_PAGE") }}</h1>

      <p class="intro-text" v-html="$t('main.PROFILE_PAGE_MY_TRIP')" />

      <v-snackbar v-model="showSavedMessage" :timeout="2000" color="success">{{ $t('main.SAVED') }}</v-snackbar>
      <div class="mytrip-category-list">

        <v-list>
          <v-list-item>
            <v-list-item-content>{{ $t("detailgroup.EnduserWorthKnowing") }}</v-list-item-content>
            <v-list-item-action>
              <v-checkbox v-model="formOrganisation.myjourneyWorthKnowing" class="mytrip-category-checkbox"/>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>   
        
          <v-list-item>
            <v-list-item-content>{{ $t("detailgroup.CaseOfEmergency") }}</v-list-item-content>
            <v-list-item-action>
              <v-checkbox v-model="formOrganisation.myjourneyCaseOfEmergency" class="mytrip-category-checkbox"/>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>   

          <v-list-item>
            <v-list-item-content>{{ $t("detailgroup.OnSiteRepresentatives") }}</v-list-item-content>
            <v-list-item-action>
              <v-checkbox v-model="formOrganisation.myjourneyOnSiteRepresentatives" class="mytrip-category-checkbox"/>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>     
          
          <v-list-item>
            <v-list-item-content>{{ $t("detailgroup.Traffic") }}</v-list-item-content>
            <v-list-item-action>
              <v-checkbox v-model="formOrganisation.myjourneyTraffic" class="mytrip-category-checkbox"/>
            </v-list-item-action>
          </v-list-item>

        </v-list>

        <div class="text-right">
          <v-btn color="primary" @click="saveOrganisation">{{ $t("main.SAVE_ACTION") }}</v-btn>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import SettingsNavigation from "@/components/settings/SettingsNavigation";

export default {
  name: 'MyTripFilterSettingsView',

  components: {
    SettingsNavigation
  },
  mounted() {
    this.loadOrganisation()
  },
  data() {
    return {
      showSavedMessage: false,

      formOrganisation: {
        myjourneyWorthKnowing: false,
        myjourneyCaseOfEmergency: false,
        myjourneyOnSiteRepresentatives: false,
        myjourneyTraffic: false
      },
    }
  },
  computed: {
    ...mapState({
      authOrganisation: state => state.authStore.organisation,
    }),
    ...mapGetters({
      organisationGetById: 'organisationStore/getById'
    }),

    organisation() {
      if (!this.authOrganisation || !this.authOrganisation.id) {
        return null
      }
      return this.organisationGetById(this.authOrganisation.id)
    },
  },
  methods: {
    ...mapActions({
      organisationFetch: 'organisationStore/fetch',
      organisationUpdate: 'organisationStore/update',
    }),

    loadOrganisation() {
      if (this.authOrganisation && this.authOrganisation.id) {
        this.organisationFetch({
          variables: {
            id: this.authOrganisation.id
          },
          force: true
        }).then(() => {
          this.fillOrganisationForm()
        })
      }
    },

    fillOrganisationForm() {
      if (this.organisation && this.organisation.isDone) {
        this.formOrganisation.myjourneyWorthKnowing = this.organisation.data.myjourneyWorthKnowing
        this.formOrganisation.myjourneyCaseOfEmergency = this.organisation.data.myjourneyCaseOfEmergency
        this.formOrganisation.myjourneyOnSiteRepresentatives = this.organisation.data.myjourneyOnSiteRepresentatives
        this.formOrganisation.myjourneyTraffic = this.organisation.data.myjourneyTraffic
      }
    },

    saveOrganisation() {

      let updateData = {
          id: this.authOrganisation.id,
          name: this.organisation.data.name,
          publicName: this.organisation.data.publicName,
          phone: this.organisation.data.phone,
          fax: this.organisation.data.fax,
          email: this.organisation.data.email,
          website: this.organisation.data.website,
          street: this.organisation.data.street,
          zipCode: this.organisation.data.zipCode,
          city: this.organisation.data.city,
          countryIso2: this.organisation.data.countryIso2,
          myjourneyWorthKnowing : this.formOrganisation.myjourneyWorthKnowing,
          myjourneyCaseOfEmergency : this.formOrganisation.myjourneyCaseOfEmergency,
          myjourneyOnSiteRepresentatives : this.formOrganisation.myjourneyOnSiteRepresentatives,
          myjourneyTraffic : this.formOrganisation.myjourneyTraffic,
        }

        this.organisationUpdate({
          variables: {
            input: updateData
          }
        }).then(() => {
          this.showSavedMessage = true
        })
    },

  },
  watch: {
    authOrganisation() {
      this.loadOrganisation()
    },
  }
}
</script>

<style scoped>
  .main-view {
    position: relative;
    padding: 2rem;
    max-width: 1250px;
    margin: auto;
  }

  .settings-sidebar {
    top: 2rem;
    width: 225px;
    position: absolute;
    left: 0;
  }

  .settings-main {
    position: absolute;
    left: 250px;
    right: 0;
  }

  .intro-text {
    margin-bottom: 2rem;
  }

  .mytrip-category-list {
    max-width: 400px;
  }

  .mytrip-category-checkbox{
    padding: 0;
    margin: 0;
    margin-left: 47%;
  }
</style>